<template>
    <div />
</template>

<script>
    import { mapGetters } from 'vuex'
    import { appSessionStorage, localStorageKey } from '@/utils/storage'
    import { logger } from '@/utils/logger'
    import { RouteNames } from '@/routes/router'

    export default {
        name: 'Logout',
        computed: {
            ...mapGetters(['isWebView', 'isSingleWebView', 'deviceInfo']),
        },
        mounted: async function () {
            logger.info('Logging Out User')
            appSessionStorage.clear()
            this.$store.commit('resetState')
            appSessionStorage.setItem(localStorageKey.deviceGuid, this.deviceInfo.deviceGuid)
            // Don't redirect when mobile app is single webview
            if (!this.isSingleWebView) {
                await this.$router.push({
                    name: RouteNames.LOGIN,
                })
            }
        },
    }
</script>
